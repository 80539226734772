import BlogClassicData from "../doob/data/blog/BlogList.json";
import SEO from "../common/SEO";
import HeaderTopNews from "../doob/common/header/HeaderTopNews";
import Header from "../common/header/Header";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "gatsby";
import ServiceOne from "../doob/elements/service/ServiceOne";
import Separator from "../components/Separator";
import SectionTitle from "../components/SectionTitle";
import TimelineOne from "../doob/elements/timeline/TimelineOne";
import VideoItem from "../doob/elements/video/VideoItem";
import TestimonialThree from "../doob/elements/testimonial/TestimonialThree";
import CalltoActionFive from "../doob/elements/calltoaction/CalltoActionFive";
import FooterOne from "../doob/common/footer/FooterOne";
import Copyright from "../doob/common/footer/Copyright";
import React from "react";
import AdvanceTabOne from "../doob/elements/advancetab/AdvanceTabOne";
import SlipThree from "../doob/elements/split/SlipThree";
import Typed from "react-typed";
import PricingOne from "../doob/elements/pricing/PricingOne";
import PricingTwo from "../doob/elements/pricing/PricingTwo";
import PricingThree from "../doob/elements/pricing/PricingThree";
import PricingFour from "../doob/elements/pricing/PricingFour";
import PricingFive from "../doob/elements/pricing/PricingFive";
import PricingFiveCopy from "../doob/elements/pricing/PricingFiveCopy";
import PricingFiveCopyTwo from "../doob/elements/pricing/PricingFiveCopyTwo";
import { BannerActivation } from "../util/script";
import Slider from "react-slick";
import TabThree from "../doob/elements/tab/TabThree";
import AboutFour from "../doob/elements/about/AboutFour";

var BlogListData = BlogClassicData.slice(0, 3);

const PopupData = [
  {
    id: "01",
    image: "https://picsum.photos/1280/800?bg-image-4.jpg",
    popupLink: [
      "https://www.youtube.com/watch?v=ctsT5Y-InqE&ab_channel=Rainbow-Themes",
    ],
  },
];

const BannerData = [
  {
    image: "https://picsum.photos/1280/800?bg-image-6.jpg",
    title: "Airports",
    description:
      "We help our clients succeed by creating brand identities, digital experiences, and print materials.",
  },
  {
    image: "https://picsum.photos/1280/800?bg-image-14.jpg",
    title: "Retail",
    description:
      "We help our clients succeed by creating brand identities, digital experiences, and print materials.",
  },
  {
    image: "https://picsum.photos/1280/800?bg-image-15.jpg",
    title: "Bars",
    description:
      "We help our clients succeed by creating brand identities, digital experiences, and print materials.",
  },
];
const HomeDefault = () => {
  return (
    <>
      <SEO title="Business Consulting" />
      <main className="page-wrapper">
        <HeaderTopNews />
        <Header transparent />

        <Slider
          className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg-overlay bg_image"
                style={{
                  backgroundImage: `url(${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-7">
                      <div className="inner text-start">
                        <h1 className="title">
                          <Typed
                            strings={["Light up", "Turn on", "Engage"]}
                            typeSpeed={80}
                            backSpeed={5}
                            backDelay={1000}
                            loop
                          />
                          <br />
                          {data.title}
                        </h1>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                        <div className="button-group mt--30">
                          <a
                            className="btn-default"
                            target="_blank"
                            href="https://themeforest.net/checkout/from_item/33571911?license=regular"
                          >
                            PURCHASE NOW
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="You can Change Easily"
                  title="Tab Style Two"
                  description=""
                />
              </div>
            </div>
            <TabThree />
          </div>
        </div>
        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 variation-default height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(https://picsum.photos/1280/800?bg-image-3.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <span className="subtitle">DIGITAL CONSULTING AGENCY</span>
                  <h1 className="title display-one">
                    Unique Business <span>Consulting</span>.
                  </h1>
                  <p className="description">
                    We help our clients succeed by creating brand identities,
                    digital experiences, and print materials.
                  </p>
                  <div className="button-group">
                    <a
                      className="btn-default btn-medium round btn-icon"
                      target="_blank"
                      href="https://themeforest.net/checkout/from_item/33571911?license=regular"
                    >
                      Purchase Now{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="#"
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                <div className="inner text-start">
                  <h1 className="title theme-gradient display-two">
                    Marketing Agency <br /> For{" "}
                    <Typed
                      strings={["Freelancer.", "Developer.", "Startup."]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <p className="description">
                    We help our clients succeed by creating brand identities,
                    digital experiences, and print materials.
                  </p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="#"
                    >
                      New Customer{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to="#"
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 order-1 order-lg-2">
                <div className="thumbnail">
                  <img
                    src="https://picsum.photos/800/800?bg-image-16.png"
                    alt="Banner Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gapBottom pt--100">
          <div className="container">
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25 rbt-border"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />
        <AboutFour image="https://picsum.photos/458/417?contact-image.jpg" />

        <Separator />
        {/* Start Timeline Area  */}
        <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Working Process"
                  title="Business Working Process."
                  description="We provide company and finance service for <br /> startups and company business."
                />
              </div>
            </div>
            <div className="row mt_dec--20">
              <div className="col-lg-12">
                <TimelineOne />
              </div>
            </div>
          </div>
        </div>
        {/* End Timeline Area  */}

        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-advance-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="You can acquire all."
                  title="Acquire new customers with Doob."
                  description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                />
              </div>
            </div>
            <AdvanceTabOne />
          </div>
        </div>
        {/* End Elements Area  */}

        <div className="rwt-split-area  rn-section-gapBottom">
          <div className="wrapper">
            <SlipThree />
          </div>
        </div>

        {/* Start Video Area  */}
        <div className="rwt-video-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              {PopupData.map((item) => (
                <div className="col-lg-12" key={item.id}>
                  <VideoItem galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Video Area  */}

        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="Our Clients Feedback."
                  description="We provide company and finance service for <br /> startups and company business."
                />
              </div>
            </div>
            <TestimonialThree teamStyle="" />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* Start Elements Area  */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;
